import { SerialComunication } from "./serial-communication";

export const DefaultPressureMeterCallbacks = () => ({

    onNextData: (data) => {},

    onUnxpectedStop: (data) => {},

    onDone: (data) => {},
})

export class PressureMeter {
    i = 0;

    constructor() {
        console.log("PressureMeter.constructor");
        
        this.serial = new SerialComunication(
            (data) => this.onStatusInfo(data),
            (data) => this.onCuffPressure(data));
    }

    onCuffPressure(data) {
        if (this.onNextData) {
            this.onNextData(this.i,data.pressure);
            
            this.i++;
        }
    }

    onStatusInfo(data) {
        if (data.systolic > 0) {
            if (this.onDone) {
                this.onDone({
                    systolic: data.systolic,
                    diastolic: data.diastolic,
                    mean: data.mean,
                    heartRate: data.heartRate,
                });
            }
        } else if (data.statusId === 2) {
            if (this.onError) {
                this.onError({
                    msg: data.messageMsg,
                });
            } 
        }
    }

    setCallbacks(onNextData, onDone, onError) {
        this.onNextData = onNextData;
        this.onDone = onDone;
        this.onError = onError;
    }

    removeCallbacks() {
        this.onNextData = undefined;
        this.onDone = undefined;
        this.onError = undefined;
    }

    async connect() {
        console.log("PressureMeter.connect");

        await this.serial.connect();
    }

    async disconnect() {
        console.log("PressureMeter.disconnect");

        await this.serial.stop();
    }

    async startSpO2() {
        await this.serial.sendCommand(this.serial.nibp.commands.startSpO2);
    }

    async stopSpO2() {
        await this.serial.sendCommand(this.serial.nibp.commands.stopSpO2);
    }

    status = "";

    waitForStatus(waitingStatus) {
        return new Promise((res, rej) => {
            setTimeout(() => {
                    
                const start = new Date().getTime();
                let end = 0;
                    
                while((end - start) < 20000) {
                    if (this.status === waitingStatus) {
                        res(true);
                        return;
                    }
                        
                    end = new Date().getTime();
                }

                console.warn("waitForStatus over.", waitingStatus);
                res(false);
            }, 1);
        });
    }

    async startBp(settings) {
        this.i = 0;

        this.status = "waitForReset";

        await this.serial.sendCommand(this.serial.nibp.commands.reset);
        await delay(1200);

        await this.serial.sendCommand(this.serial.nibp.commands.requestData);
        // if (!(await this.waitForStatus("reseted"))) return;
        await delay(100);

        switch (settings.method) {
            case 1:
                await this.serial.sendCommand(this.serial.nibp.commands.setMethod1);
                break;
            case 2:
                await this.serial.sendCommand(this.serial.nibp.commands.setMethod2);
                await delay(50);
                await this.serial.sendCommand(this.serial.nibp.commands.setPressure);
                break;
            case 3:
                await this.serial.sendCommand(this.serial.nibp.commands.setMethod3);
                break;
            default:
                break;
        }

        await delay(50);

        if (settings.mode === "adult") {
            await this.serial.sendCommand(this.serial.nibp.commands.setAdultMode);
            await delay(50);
            await this.serial.sendCommand(this.serial.nibp.commands.setAdult[settings.maxPressureAdult]);
            console.log("setMaxPressure:adult:", this.serial.nibp.commands.setAdult[settings.maxPressureAdult]);
            await delay(50);
        } else {
            await this.serial.sendCommand(this.serial.nibp.commands.setNeonatalMode);
            await delay(50);
            await this.serial.sendCommand(this.serial.nibp.commands.setNeonatal[settings.maxPressureNeonatal]);
            console.log("setMaxPressure:neonatal:", this.serial.nibp.commands.setNeonatal[settings.maxPressureNeonatal]);
            await delay(50);
        }

        switch (settings.speed) {
            case 30:
                await this.serial.sendCommand(this.serial.nibp.commands.setPumpingTime30);
                break;
            case 45:
                await this.serial.sendCommand(this.serial.nibp.commands.setPumpingTime45);
                break;
            default:
                break;
        }

        // await this.serial.sendCommand(this.serial.nibp.commands.manualMeasuring);
        // await delay(50);
        // await this.serial.sendCommand(this.serial.nibp.commands.setPressure);
        // await delay(50);

        await this.serial.sendCommand(this.serial.nibp.commands.startMeasuring);
      
    }

    async stopBp() {
        console.log("PressureMeter.stopBp");

        await this.serial.bp.stopMeassuring();
    }
}

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
