import React from 'react';
import {Modal, Button, OverlayTrigger, Tooltip, Form} from 'react-bootstrap';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  //Tooltip,
  Legend,
  TimeSeriesScale,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import './MeassuringModal.css';
//import {MockPressureMeter} from "../pressureMeter/MockPressureMeter";
import {PressureMeter} from "../pressureMeter/PressureMeter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeSeriesScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
//  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,

  //animation: false,
  animations: {
    y: {
        duration: 0,
    },
    x: {
        duration: 0,
    },
  },
  transitions: {
    show: {
        animation:{
            duration: 0,
        }
    }
  },
  
  plugins: {
    legend: {
        display: false,
        position: 'top',
    },
    tooltip: {
        callbacks: {
            
            //title: (items) => "test",
            label: (item) => `${item.dataset.label}: ${item.formattedValue} mmHg`,
        },
    },
  },

    scales: {
        x: {
            type: 'linear',
            ticks: {
                callback: function(value, index, values) {
                        return value + ' s';
                }
            },
        },
        y: {
            type: 'linear',
            min: 0,
            max: 250,
            title: { display: true, text: "mmHg", align: 'end'}
        }
    },

};

function chartDefaultData() {
    return {
        // labels,
        datasets: [
            {
            label: 'Tlak manžety',
            data: [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    }
}

const Modes = {
    init: "init",
    connected: "connected",
    meassuring: "meassuring",
    stopped: "stopped",
    results: "results",
}

// const meassuringDevice = new MockPressureMeter();
const meassuringDevice = new PressureMeter();

function MeassuringModal(props){
    const [ meassuredValues, setMeassuredValues ] = React.useState({
        systolic: 0,
        diastolic: 0,
        mean: 0,
        heartRate: 0,
    });
    const [ settings, setSettings ] = React.useState({
        mode: "adult",
        maxPressureAdult: 160,
        maxPressureNeonatal: 80,
        method: 1,
        speed: 15,
        delay: 0,
    })
    const [ mode, setMode ] = React.useState(Modes.init);
    const [ chartData, setChartData ] = React.useState(chartDefaultData());
    const [ currentValue, setCurrentValue ] = React.useState(0);
    const [ errorMsg, serErrorMsg ] = React.useState(undefined);
    const chartReference = React.useRef(null);

    React.useEffect(() => {
        if (!props.show) {
            if (mode === Modes.meassuring) {
                console.log("Vynucené ukončení při zavření dialogu.")
                setChartData(data => data);
                setMode(Modes.stopped);
                meassuringDevice.stopBp();
            }

            return;
        }

        meassuringDevice.setCallbacks(onNextData, onDone, onError);

        if (mode === Modes.init) {
            console.log("Automatické připojení při otevření dialogu.")
            setMode(Modes.connected);
            meassuringDevice.connect();
        }

        return () => {
            meassuringDevice.removeCallbacks();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    function onNextData(i, value) {
        if (mode !== Modes.meassuring) {
            setMode(Modes.meassuring);
        }
        //const date = new Date();
        
        chartData.datasets[0].data.push({ x: i / 5, y: value });
        setCurrentValue(value);

        updateChart();
    }

    function onDone(values) {
        setMeassuredValues(values);
        setMode(Modes.results);
        setChartData(chartData);
    }
    
    function onError(values) {
        serErrorMsg(values.msg);
        setMode(Modes.stopped);
    }

    function updateChart() {
        const chart = chartReference.current;
        if (chart) {
            chart.update();
        }
    }

    function newMeassuring() {
        chartData.datasets[0].data.length = 0;
        updateChart();
        serErrorMsg(undefined);
        setMode(Modes.connected);
    }

    function startMeassuring() {
        if (mode === Modes.meassuring) {
            console.warn("Měření právě probíhá, nelze spustit další měření.");
            return;
        }

        setCurrentValue(0);

        setMode(Modes.meassuring);

        setTimeout(() => {
            meassuringDevice.startBp(settings);
        }, settings.delay * 1000);
    }

    function stopMeassuring() {
        if (mode !== Modes.meassuring) {
            //console.warn("Nelze vypnout měžení. Žádné neprobíhá.");
            return;
        }

        setChartData(data => data);
        setMode(Modes.stopped);
        meassuringDevice.stopBp();
    }

    let infoColumn = <div></div>;
    if (mode === Modes.results) {
        infoColumn = <React.Fragment>
            <div className="meassured-value-container">
                <h5 className="meassured-value-title">Systolický tlak</h5>
                <span className='meassured-value'>{meassuredValues.systolic}</span>
                <span className='meassured-unit'>mmHg</span>
            </div>
            <div className="meassured-value-container">
                <h5 className="meassured-value-title">Střední tlak</h5>
                <span className='meassured-value'>{meassuredValues.mean}</span>
                <span className='meassured-unit'>mmHg</span>
            </div>
            <div className="meassured-value-container">
                <h5 className="meassured-value-title">Diastolický tlak</h5>
                <span className='meassured-value'>{meassuredValues.diastolic}</span>
                <span className='meassured-unit'>mmHg</span>
            </div>
            <hr />
            <div className="meassured-value-container">
                <h5 className="meassured-value-title">Tep</h5>
                <span className='meassured-value'>{meassuredValues.heartRate}</span>
                <span className='meassured-unit'>bpm</span>
            </div>
        </React.Fragment>
    } else if (mode === Modes.meassuring || mode === Modes.stopped) {
        infoColumn = <React.Fragment>
            <div className="meassuring-value-container">
                <h5 className="meassured-value-title">Tlak v manžetě</h5>
                <span className='meassured-value messuring'>{currentValue}</span>
                <span className='meassured-unit'>mmHg</span>
                { errorMsg && <div className='meassuring-error'><b>Chyba:</b><br/>{errorMsg}</div> }
            </div>
        </React.Fragment>
    } else if (mode === Modes.connected) {
        infoColumn = <React.Fragment>
            <Form>
                <Form.Group>
                    <Form.Label><h5>Mód</h5></Form.Label>
                    <Form.Select value={settings.mode}
                        onChange={(event)=>setSettings({...settings, mode: event.target.value})}>
                        <option value={"adult"}>dospělý</option>
                        <option value={"neonatal"}>novorozenecký</option>
                    </Form.Select>
                </Form.Group>
                { settings.mode === "adult" && <Form.Group>
                    <Form.Label><h5>Maximální tlak</h5></Form.Label>
                    <Form.Select value={settings.maxPressureAdult} 
                        onChange={(event)=>setSettings({...settings, maxPressureAdult: Number.parseInt(event.target.value)})}>
                        <option value={80} >80 mmHg</option>
                        <option value={100}>100 mmHg</option>
                        <option value={120}>120 mmHg</option>
                        <option value={140}>140 mmHg</option>
                        <option value={160}>160 mmHg</option>
                        <option value={180}>180 mmHg</option>
                        <option value={200}>200 mmHg</option>
                        <option value={220}>220 mmHg</option>
                        <option value={240}>240 mmHg</option>
                        <option value={280}>280 mmHg</option>
                    </Form.Select>
                </Form.Group> }
                { settings.mode === "neonatal" && <Form.Group>
                    <Form.Label><h5>Maximální tlak</h5></Form.Label>
                    <Form.Select value={settings.maxPressureNeonatal} 
                        onChange={(event)=>setSettings({...settings, maxPressureNeonatal: Number.parseInt(event.target.value)})}>
                        <option value={60} >60 mmHg</option>
                        <option value={80} >80 mmHg</option>
                        <option value={100}>100 mmHg</option>
                        <option value={120}>120 mmHg</option>
                    </Form.Select>
                </Form.Group> }
                <Form.Group>
                    <Form.Label><h5>Metoda měření</h5></Form.Label>
                    <Form.Select value={settings.method} 
                        onChange={(event)=>{
                                setSettings({...settings, method: Number.parseInt(event.target.value)});
                                props.setMethodFromSettings(event.target.value);
                            }
                        }>
                        <option value={1} >Metoda 1</option>
                        <option value={2} >Metoda 2</option>
                        <option value={3} >Metoda 3</option>
                    </Form.Select>
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label><h5>Doba měření</h5></Form.Label>
                    <Form.Select value={settings.speed} 
                        onChange={(event)=>setSettings({...settings, speed: Number.parseInt(event.target.value)})}>
                        <option value={15} >15 s</option>
                        <option value={30} >30 s</option>
                        <option value={45} >45 s</option>
                    </Form.Select>
                </Form.Group> */}
                <Form.Group>
                    <Form.Label><h5>Odklad měření</h5></Form.Label>
                    <Form.Select value={settings.delay} 
                        onChange={(event)=>setSettings({...settings, delay: Number.parseInt(event.target.value)})}>
                        <option value={0} >bez odkladu</option>
                        <option value={5} >5 s</option>
                        <option value={10} >10 s</option>
                        <option value={15} >15 s</option>
                    </Form.Select>
                </Form.Group>
            </Form>
        </React.Fragment>
    }

    const debug = false;

    const stavText = 
        (mode === Modes.init) ? ""
        : (mode === Modes.connected) ? " - nastavení"
        : (mode === Modes.meassuring) ? " - probíhá"
        : (mode === Modes.results) ? ""
        : (mode === Modes.stopped) ? " - přerušeno"
        : ""

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            dialogClassName="meassuring-modal"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Měření krevního tlaku {stavText}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="meassuring-container">
                    <div className="meassuring-values">
                        {infoColumn}
                    </div>

                    { (mode === Modes.meassuring || mode === Modes.results || mode === Modes.stopped) &&
                        <div className="meassuring-chart">
                            <Line ref={chartReference} options={chartOptions} data={chartData} />
                        </div>
                    }

                    {   (mode=== Modes.connected) &&
                        <div>
                            <h5>Poznámky:</h5>
                            <ul>
                                <li>"Metoda 1" - měření během vyfukování.</li>
                                <li>"Metoda 2" - měření během tlakování. Měřený tlak musí být v rozsahu 120-200, jinak se použije Metoda 1.</li>
                                <li>"Metoda 3" - měření během vyfukování. Nebere se v potaz nastavený maximální tlak, který se přístroj snaží při nafukování odhadnout z posledního měření.</li>
                                <li>U metod 1 a 2 se vždy aplikuje nastavený maximální tlak - je potlačena schopnost přístroje používat hodnotu z minulého měření.</li>
                                <li>Pro "novorozenecký mód" se vždy použije "Metoda 1".</li>
                            </ul>
                        </div>

                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                { debug && <Button onClick={() => meassuringDevice.startBp()} variant="outline-danger">Start BP</Button> }
                { debug && <Button onClick={() => meassuringDevice.stopSpO2()} variant="outline-danger">Stop SpO2</Button> }
                { debug && <Button onClick={() => meassuringDevice.startSpO2()} variant="outline-danger">Start SpO2</Button> }
                { debug && <Button onClick={() => meassuringDevice.serial.sendCommand(meassuringDevice.serial.nibp.commands.requestData)} variant="outline-danger">Request data</Button> }
                { (mode === Modes.results || mode === Modes.stopped || debug) && <Button onClick={newMeassuring} variant="outline-danger">Změřit znovu</Button> }
                { (mode === Modes.connected || debug) && <Button onClick={startMeassuring} variant="outline-danger">Spustit měření</Button> }
                { (mode === Modes.meassuring || debug) && <Button onClick={stopMeassuring} variant="outline-danger">Ukončit měření</Button> }
                <span style={{flexGrow: 1}}></span>
                {/* <Button onClick={props.handleClose} variant="secondary">Zavřít</Button> */}
                <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled">
                        { (mode !== Modes.results)
                            ? <span>Nelze vložit.<br />Měření nedokončeno.</span> 
                            : <span>Vložit naměřeného hodnoty do formuláře.</span>
                        }
                    </Tooltip>
                }>
                    <span className="d-inline-block">
                        <Button onClick={() => props.handleSave(meassuredValues, chartData.datasets[0].data)} variant="primary"
                            disabled={mode !== Modes.results}>
                            Použít
                        </Button>
                    </span>
                </OverlayTrigger>
            </Modal.Footer>
        </Modal>
    );
}

export default MeassuringModal;