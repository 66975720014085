import {Form, Row, Col, Button, Nav} from 'react-bootstrap';
import { connect } from 'react-redux';
import { login } from '../actions/account';
import React, { useState, useEffect } from 'react';
import {history} from '../store';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstAttempt, setFirstAttemt] = useState(true);

    const login = () => { 
        props.login({ username, password });
        setFirstAttemt(false);
        setPassword(""); 
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [username, password])

    useEffect(() => {
        if(props.account.loggedIn){
            history.push('/')
        } 
    }, [props.account.loggedIn])

    function onKeyDown(e){
        if(e.keyCode == 13){
            login();
        }
    }

    return (
        <div className="app">
            <div className="page">
                <div className="content">
                    <Form>
                        <Form.Group className="mb-2">
                            <img src='bme.png' style={{width: '350px', maxWidth: '100%', margin: '0 auto', display: 'block', textAlign: 'center'}}></img>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <div style={{width: '350px', maxWidth: '100%', margin: '0 auto', display: 'block', textAlign: 'center', fontSize: 'xx-large', fontWeight: 'bolder'}}>Přihlášení</div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="text"
                                autoComplete='new-password' 
                                placeholder="Uživatel" 
                                style={{width: '350px', maxWidth: '100%', margin: '0 auto'}}
                                value={username} 
                                onChange={(event)=>setUsername(event.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="password" 
                                autoComplete='new-password' 
                                placeholder="Heslo" 
                                style={{width: '350px', maxWidth: '100%', margin: '0 auto'}}
                                value={password} 
                                onChange={(event)=>setPassword(event.target.value)}
                            />
                        </Form.Group>
                        {
                            !firstAttempt && props.account.status === 'error' && 
                            <div className="mb-3" style={{width: '350px', maxWidth: '100%', margin: '0 auto', display: 'block', color: 'red', textAlign: 'center'}}>{props.account.message}</div>
                        }

                        <Button variant="primary" onClick={login} style={{width: '350px', maxWidth: '100%', margin: '0 auto', display: 'block'}}>
                            Přihlásit
                        </Button>
                        <Nav>
                            <Nav.Link href="/" style={{width: '350px', maxWidth: '100%', margin: '0 auto', display: 'block', textAlign: 'center', fontSize: 'medium'}}>Zapomenuté heslo</Nav.Link>
                        </Nav>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default connect(
    ({ account }) => ({ account }),
    { login }
  )(Login);
