const ACCOUNT = {
    FETCH: 'ACCOUNT_FETCH',
    FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
    FETCH_SUCCESS: 'ACCOUNT_FETCH_SUCCESS',
    FETCH_LOGOUT_SUCCESS: 'ACCOUNT_FETCH_LOGOUT_SUCCESS',
    FETCH_AUTHENTICATED_SUCCESS: 'ACCOUNT_FETCH_AUTHENTICATED_SUCCESS'
};

export {
    ACCOUNT
};