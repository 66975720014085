import React from 'react';
import BloodPressureList from './pages/BloodPressureList';
import BloodPressureMeasurement from './pages/BloodPressureMeasurement';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavbar from "./components/CustomNavbar";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import {history, store} from './store';

import { Route, Switch, Redirect, Router } from 'react-router-dom'
import { fetchAuthenticated } from "./actions/account";
import About from './pages/About';
import Login from './pages/Login';
import Functionality from './pages/Functionality';


const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const AuthRoute = props => {
    if(!store.getState().account.loggedIn){
        return <Redirect to={{pathname: '/login'}}/>
    }

    const {component, path} = props;

    return <Route path={path} component={component}/>;
}

const NonAuthRoute = props => {
    if(store.getState().account.loggedIn && store.getState().account.userRole === 'Admin'){
        return <Redirect to={{pathname: '/measurement'}}/>
    }
    else if(store.getState().account.loggedIn){
        return <Redirect to={{pathname: '/list'}}/>
    }

    const {component, path} = props;

    return <Route path={path} component={component}/>;
}

store.dispatch(fetchAuthenticated())
.then(() => {
    root.render(
        <Provider store={store}>
            <Router history={history}>
                <>
                    <CustomNavbar></CustomNavbar>
                    <div>
                        <Switch>
                            <AuthRoute path='/measurement' component={BloodPressureMeasurement} />
                            <AuthRoute path='/list' component={BloodPressureList} />
                            <AuthRoute path='/functionality' component={Functionality} />
                            <AuthRoute path='/about' component={About} />
                            <NonAuthRoute path='/login' component={Login} />
                            <NonAuthRoute path='/' component={About} />
                        </Switch>
                    </div>
                </>
            </Router>
        </Provider>
    )
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
