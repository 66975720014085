const BACKEND = {
    ADDRESS: 'https://bloodpressurecollector-6020.rostiapp.cz'
};


/*
const BACKEND = {
    ADDRESS: 'http://localhost:8080'
};*/

export {BACKEND};