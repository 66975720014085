
import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../App.css';

function About() {

    return (
        <div className="app">
            <div className="page">
                <div className="content">
                    <div className='about-text'>
                        <div className='about-image mb-3'>
                            <img src='bme.png'></img>
                        </div>
                        
                        <p>
                            Aplikace je pomocným prvkem pro výzkum jehož cílem je zkoumat střední arteriální tlak vzhledem
                            k hodnotám systolického a diastolického krevního tlaku a jeho vliv. Aplikace slouží
                            k zadávání a ukládání klinických dat získaných současným měřením krevního tlaku auskultační
                            i oscilometrickou metodou za účelem získání synchronních dat.
                        </p>

                        <p>
                            Naměřená data jsou vložena do databáze, 
                            která obsahuje pohlaví a rok narození pacienta, jeho zdravotní stav (porucha srdečního rytmu, užívaná 
                            medikace a klasifikace hypertenze) a naměřené hodnoty krevního tlaku a srdečního tepu. Údaje z měření jsou 
                            zpracovány a analyzovány. 
                        </p>
                        
                        <p>Získaná klinická data budou po získání anonymně uložena a budou součástí disertační práce.</p>
                         
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;