import React, { useState, useEffect } from 'react';
import {Modal, Button} from 'react-bootstrap';

function CopyModal(props){

    const [copied, setCopied] = useState(false);;

    async function handleCopyClick() {
        await navigator.clipboard.writeText(props.text);
        setCopied(true);
    }

    return (
        <Modal
            show={props.show}
            onHide={() => {
                setCopied(false);
                props.handleClose();  
            }}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Hodnoty z měření
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{whiteSpace: 'pre-line'}}>
                {props.text}
                </p>
                <Button variant='outline-success' onClick={handleCopyClick} style={{margin: '0 auto', display: 'block'}}>Zkopírovat do schránky</Button>
                {
                    copied &&
                    <div style={{textAlign: 'center', color: '#198754', marginTop: '5px'}}>
                        Text byl zkopírován
                    </div>
                }
            </Modal.Body>
            <Modal.Footer style={{justifyContent: "center"}}>
                <Button onClick={() => {
                    setCopied(false);
                    props.handleClose();  
                }}>
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CopyModal;