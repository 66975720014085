import rootReducer from "./reducers";
import { createStore, compose, applyMiddleware } from 'redux';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk'

export const history = createBrowserHistory()

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))  
);


