import React, { useState } from 'react';
import {Modal, Button} from 'react-bootstrap';

function InfoModal(props){

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Oznámení
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                {props.text}
                </p>
            </Modal.Body>
            <Modal.Footer style={{justifyContent: "center"}}>
                <Button onClick={props.handleClose}>Zavřít</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default InfoModal;