// @ts-check
// eslint-disable-next-line no-unused-vars
import { BpState, SerialComunication } from "./serial-communication";

export class BpMeasuring {

    /**
     *
     * @param {SerialComunication} serial
     */
    constructor(serial, onStatusInfo, onCuffPressure) {
        this.serial = serial;
        this.onStatusInfo = onStatusInfo;
        this.onCuffPressure = onCuffPressure;
    }

    /**
     * Zpracování příhozí zprávy
     * @param {string} msg
     */
    async readMessage(msg) {
        // check end message
        if (msg === "999") {
            await this.requestState();
            return;
        }
        
        const cuffPressure = this.serial.nibp.parseCuffPressure(msg);
        if (cuffPressure) {
            if (this.onCuffPressure)
                this.onCuffPressure(cuffPressure);

            return;
        }

        var status = this.serial.nibp.parseStatusMassage(msg);
        if (status) {
            this.state = BpState.Idle;
            if (this.onStatusInfo)
                this.onStatusInfo(status);
        }
    }

    async startMeassuring() {
        this.serial.sendCommand(this.serial.nibp.commands.startMeasuring);
    }

    async stopMeassuring() {
        this.serial.sendCommand(this.serial.nibp.commands.abort);
    }

    async requestState() {
        this.serial.sendCommand(this.serial.nibp.commands.requestData);
    }
}
