
export class Nibp2010 {
    
    get baudRate() { return 4800; }

    get bits() { return bits_2010; }

    get commands() { return commands_2010; }

    statusState(id) {
        const state = statusState[id];

        if (state) {
            return state;
        }

        console.error(`Požadován neznámy stav (id: ${id})`);
        return "---";
    }

    statusMessage(id) {
        const state = statusMessages[id];

        if (state) {
            return state;
        }

        console.error(`Požadován neznámy stav (id: ${id})`);
        return "---";
    }

    parseStatusMassage = ParseStatusMassage;

    parseCuffPressure = ParseCuffPressure;

}

export class Nibp2020 extends Nibp2010 {

    get baudRate() { return 19200; }

    get bits() { return bits_2020; }

    get commands() { return commands_2020; }

    parseStatusMassage = ParseStatusMassage;

    parseCuffPressure = ParseCuffPressure;

}

const bits_2010 = {
    start: 0x02,
    end: 0x03,
    newLine: 0x13,
}

const bits_2020 = {
    start: 0xFD,
    end: 0xFE,
    newLine: 0x0D,
}

const commands_2010 = {                                          
    startMeasuring:  "01;;D7",
    manualMeasuring: "03;;D9",

    requestData: "18;;DF",

    setNeonatalMode: "24;;DC",                                                                                   
    setAdultMode:    "25;;DD",

    setNeonatal: {
        60:  "36;;DF",
        80:  "37;;D7",                
        100: "19;;E0",
        120: "20;;D8",                            
    },

    setAdult: {
        80:  "30;;D9",
        100: "31;;DA",
        120: "32;;DB",
        140: "21;;D9",
        160: "22;;DA",
        180: "23;;DB",
        200: "33;;DC",
        220: "34;;DD",
        240: "35;;DE",
    },

    abort: "X"
}


const commands_2020 = {                                          
    startMeasuring:  "01;;D7",
    manualMeasuring: "03;;D9",

    requestData: "18;;DF",

    reset: "16;;DD",
    abort: "X",

    stopSpO2: "30;;D9",
    startSpO2: "31;;DA",

    setMethod1: "55;;E0",
    setMethod2: "56;;E1",
    setMethod3: "65;;E1",

    setNeonatalMode: "25;;DD",
    setNeonatal: {
        60:  "36;;DF",
        80:  "37;;D7",                
        100: "19;;E0",
        120: "20;;D8",                            
    },

    setAdultMode: "24;;DC",
    setAdult: {
        80:  "60;;DC",
        100: "61;;DD",
        120: "62;;DE",
        140: "21;;D9",
        160: "22;;DA",
        180: "23;;DB",
        200: "33;;DC",
        220: "34;;DD",
        240: "35;;DE",
        280: "38;;E1",
    },

    setPressure: "66;;E2",

    setPumpingTime30: "90;;DF",
    setPumpingTime45: "91;;E0",
}

const statusState = {
    0: "probíhá automatický test", // auto-test in progress (immediately after reset)
    1: "čekání na příkazy", // waiting for commands (standby), cycle counter stopped
    2: "chyba", // error (evaluation of error bits), cycle counter stopped
    3: "probíhá měření", // measuring in progress
    4: "režim manometru", // manometer mode
    5: "probíhá inicializace", // initialization (immediately after reset) in progress
    6: "cyklická nebo kontinuální mód", // cycle-/continuous- mode
    7: "zkouška těsnosti", // leakage test
    8: "inflating to supra-systolic pressure", // inflating to supra-systolic pressure
    9: "holding to supra-systolic pressure", // holding to supra-systolic pressure
}

const statusMessages = {
    0: "nepřerušený provoz", // uninterupted operation
    2: "neplatný příkaz", // receiving invalid command
    3: "nepřerušený provoz", // uninterupted operation
    6: "příliš volně nasazená nebo nepřipojená manžeta", // cuff fitted too loosely or not connected, time for pumping exceeded
    7: "únik tlaku manžety", // cuff leakage 
    8: "porucha pneumatiky", // pneumatics faulty
    9: "puls není rozpoznatelný", // meassiring time exceeded, current pressure smaller than the lowwer limit of diaslote, too less oscilation detected
    10: "systola a diastola mimo tlakový rozsah", // systolic and dialsotlic values are outside the pressure range
    11: "příliš silný pohybový artefakt", // too strong movement artefact
    12: "překročení maximálního tlaku", // maximum pressure exceded (in accordance with IEC limits)
    13: "saturovaný (příliš velký) pulzní signál", // two saturated oscilation amlitudes are detected (waveform check)
    14: "zjištěna netěsnost při zkoušce těsnosti", // leakage determined during leakage test
    15: "systémová chyba v měřicí jednotce", // system error in measuring unit
}

/**
 * @typedef BpMessage
 * @type {object}
 * @property {string[]} parts
 */

/**
 * Pressure meter state
 * @readonly
 * @enum {number}
 */
export const OperationalMode = {
    Adult: 0,
    Neonatal: 1,
}

/**
 * @typedef BpStatusTransmissionData
 * @type {object}
 * @property {number} statusId
 * @property {OperationalMode} modeId
 * @property {number} cycle cycle node in minutes
 * @property {number} messageId
 * @property {number} systolic
 * @property {number} diastolic
 * @property {number} mean
 * @property {number} heartRate
 * @property {number} nextMeasurement in seconds (for cycle mode)
 */

/**
 * Split message to arrray of parameters
 * @param {string} msg message from serial comunication with bp
 * @returns {string[] | undefined} array of parameters
 */
function ParseMessage(msg) {
    if (!msg) {
        return;
    }
    
    const checkSumPosition = msg.indexOf(";;");

    if (checkSumPosition) {
        msg = msg.substring(0, checkSumPosition);
    }

    return msg.split(";")
}


/**
 * @param {string} msg
 */
export function ParseStatusMassage(msg) {
    const parts = ParseMessage(msg);

    if (!parts || parts.length < 7) {
        return;
    }

    var statusId =  ParseInt(parts[0], true);
    var messageId = ParseInt(parts[3], true);

    /** @var {BpStatusTransmissionData} */
    const statusData = {
        statusId: statusId,
        statusMsg: statusState[statusId],
        modeId: ParseInt(parts[1], true),
        cycle: ParseInt(parts[2], true),
        messageId: messageId,
        messageMsg: statusMessages[messageId],
        systolic: ParseInt(parts[4].substring(1, 4)),
        diastolic: ParseInt(parts[4].substring(4, 7)),
        mean: ParseInt(parts[4].substring(7)),
        heartRate: ParseInt(parts[5], true),
        nextMeasurement: ParseInt(parts[6], true),
    }

    return statusData;
}

const regexCuffPressure = /^(\d{3})C(\d{1})S(\d{1})$/;

export function ParseCuffPressure(msg) {
    const match = msg.match(regexCuffPressure);

    if (match !== null) {
        return {
            pressure: ParseInt(match[1]),
            caution: ParseInt(match[2]),
            status: ParseInt(match[3]),
        }
    }
}

function ParseInt(value, prefix = false, defaultValue = 0) {
    if (prefix) {
        value = value.substring(1);
    }

    const number = parseInt(value, 10);

    if (isNaN(number)) {
        return defaultValue;
    }

    return number;
}


// export function IsStatusMessage(msg) {
//     if (msg) {
//         return (String.match(new RegExp(";", "g")) || []).length > 5;
//     }

//     return false;
// }
