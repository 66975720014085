// @ts-check
/// <reference types="@types/w3c-web-serial" />

import { BpMeasuring } from "./BpMeasuring";
import { Nibp2020 } from "./nipb2010";

/**
 * Pressure meter state
 * @readonly
 * @enum {number}
 */
export const BpState = {
    Idle: 1,
    Meassuring: 2,
    LeakageTest: 3,
}

export class SerialComunication {

    constructor(onStatusInfo, onCuffPressure) {
        /** @readonly */
        this.bp = new BpMeasuring(this, onStatusInfo, onCuffPressure);

        this.nibp = new Nibp2020();
    }

    /** Vytvořit spojení s NIBP včetně výběru portu */
    async connect() {
        if (!this.port) {
            const ports = await this.availableDevices();

            if (ports.length === 1) {
                this.port = ports[0];

            } else if (ports.length > 1) {
                this.forgetPorts(ports);
                this.port = await this.requestNewPort()

            } else {
                this.port = await this.requestNewPort()
            }
        }

        if (!this.port) {
            console.error("Nelze se připojit, nebyl zadán port.");
            return;
        }

        await this.openPort();

        // if (this.nibp.commands.stopSpO3){
        //     console.log("Vypínám SpO3");
        //     await this.sendCommand(this.nibp.commands.stopSpO3);
        // }
        
        this.startReadingLoop();
    }

    async openPort() {
        if (!this.port) {
            console.error("Nelze se připojit, nebyl zadán port.");
            return;
        }

        try {
            await this.port.open({
                baudRate: this.nibp.baudRate,
                bufferSize: 16777216,
                dataBits: 8,
                flowControl: "none",
                parity: "none",
                stopBits: 1,
            });

            console.log("Připojení k sériovému portu proběhlo úspěšně.");
            
        } catch (error) {
            console.error("Nepovedlo se připojeník sériovému portu.", error);
        }
    }

    /** Ukončit spojení s NIBP */
    async stop() {
        this.stopReadingLoop();
    }

    /**
     * Zobrazí uživateli dialog se žádostí o povolení vybraného portu
     * @private
     * @returns {Promise<SerialPort>}
     */
    requestNewPort() {
        return navigator.serial.requestPort();
    }

    /**
     * Odebere porty z povolených
     * @private
     * @param {SerialPort[]} ports
     */
    async forgetPorts(ports) {
        await Promise.all(ports.map(p => p.forget()))
    }

    /**
     * Vrátí seznam dříve povolených portů
     * @private
     */
    async availableDevices() {
        return await navigator.serial.getPorts();

        // const infos = ports.map(p => p.getInfo());

        // return ports.map((p, i) => ({
        //     port: p,
        //     deviceId: infos[i].usbProductId,
        //     vendorId: infos[i].usbVendorId
        // }))
    }

    // async findDefaultPort() {
    //     const ports = await this.availableDevices();
    //     return ports.find(p => p.deviceId === 24577)?.port;
    // }



    readingLoopRunnig = false;

    async startReadingLoop() {
        if (this.readingLoopRunnig) {
            console.error("Čtecí smyčka už běží.");
            return;
        }

        this.readingLoopRunnig = true;

        /** @var {string | undefined} */
        let messageBuffer;
        let lastBitEnd = false;

        while (this.port?.readable && this.readingLoopRunnig) {
            const reader = this.port.readable.getReader();

            try {
                while (this.readingLoopRunnig) {
                    const response = await reader.read();
                    if (response.done) {
                        // |reader| has been canceled.
                        this.readingLoopRunnig = false;
                        break;
                    }

                    var byteArray = response.value;
    
                    //console.log([...value]);
    
                    for (let jj = 0; jj < byteArray.length; jj++) {
                        const byteValue = byteArray[jj];
                        
                        if (byteValue === this.nibp.bits.newLine && lastBitEnd) {
                            // nic když je nový řádek
                            lastBitEnd = false;
                            continue;
                        }

                        lastBitEnd = false;
                        
                        if (byteValue === this.nibp.bits.end) {
                            if (messageBuffer !== undefined) {
                                try {
                                    await this.processBP(messageBuffer);
                                } catch (error) {
                                    console.error("Nastala chyba při zpracování zprávy", {messageBuffer}, error);
                                }
                                messageBuffer = undefined;
                                lastBitEnd = true;
                            }
    
                        } else if (byteValue === this.nibp.bits.start) {
                            messageBuffer = "";
    
                        } else if (messageBuffer !== undefined) {
                            messageBuffer += String.fromCharCode(byteValue);
    
                        } else {
                            try {
                                this.processOtherBits(byteValue);
                            } catch (error) {
                                console.error("Nastala chyba při zpracování 'processOtherBits'", {byteValue}, error);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Nastala chyba při zpracování čtení ze sériového portu", error);
                //await this.openPort();
            } finally {
                reader.releaseLock();
              //  await this.port?.close();
            }
        }
    }

    stopReadingLoop() {
        if (!this.readingLoopRunnig) {
            console.warn("Čtecí smyčka nemůže být ukončena protože neběží.");
        }
        
        this.readingLoopRunnig = false;
    }

    /**
     * 
     * @param {string} value 
     */
    processBP(value) {
        console.log("BP:" + value);
        return this.bp.readMessage(value);
    }
    
   
    /**
     * 
     * @param {number} value 
     */
    async processOtherBits(value) {

        await this.sendCommand(this.nibp.commands.stopSpO2);

        console.log("SpO2: " + value.toString());
    }


    sending = false;

    /**
     * @param {string | undefined} msg
     */
    async sendCommand(msg) {
        console.log("SendCommand:" + msg);

        if (this.sending) {
            console.warn("Nelze odeslat zprávu, právě se posílá jiná!")
            return;
        }

        this.sending = true;

        if (!this.port){
            console.warn("Nelze odeslat zprávu, není zadán port!")
            return;
        }
        
        if (!this.port?.writable){
            console.warn("Nelze odeslat zprávu, do portu nelze zapisovat!")
            return;
        }

        const encoder = new TextEncoder();
        const writer = this.port.writable.getWriter();
        if (!writer) {
            console.warn("Nelze odeslat zprávu, chyba při získání writeru!")
            return;
        }

        const fullMsg = [ 
            this.nibp.bits.start,
            ...encoder.encode(msg),
            this.nibp.bits.end
        ];

        await writer.write(new Uint8Array(fullMsg));
        writer.releaseLock();
        this.sending = false;
    }
}
