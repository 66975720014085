
import React, { useState, useEffect } from 'react';
import {Form, Row, Col, Button, Table, Pagination, ListGroup, Spinner } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../App.css';

function Functionality() {

    return (
        <div className="app">
            <div className="page">
                <div className="content">
                    <div className='about-text'>
                        <h1 className="mb-3">
                            Funkcionalita
                        </h1>
                        <h3 className="mb-2 mt-5">
                            Údaje o pacientovi
                        </h3>
                        <p>
                            Před měřením je standartní vyplnit údaje o pacientovi. Po vyplnění rodného čísla se automaticky vyplní pohlaví a datum narození. 
                            Jakmile se vyplní hmotnost a výška v daných jednotkách, vypočte se hodnota BMI. 
                            Dále je vhodné vyplnit obvod paže a zadat typ použité manžety pro měření, na výběr je mezi klasickou a větší manžetou, 
                            jejich rozměry a ideální obvod paže pro danou manžetu jsou v tabulce.
                        </p>
                        <Table responsive striped bordered hover style={{textAlign: 'center'}}>
                            <thead style={{backgroundColor: "lightgray"}}>
                                <tr>
                                    <th>
                                        Manžeta
                                    </th>
                                    <th>
                                        Šířka gumového vaku (cm)
                                    </th>
                                    <th>
                                        Délka gumového vaku (cm)
                                    </th>
                                    <th>
                                        Obvod paže (cm)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Klasická
                                    </td>
                                    <td>
                                        13
                                    </td>
                                    <td>
                                        30
                                    </td>
                                    <td>
                                        27 - 34
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Větší
                                    </td>
                                    <td>
                                        16
                                    </td>
                                    <td>
                                        38
                                    </td>
                                    <td>
                                        35 - 44
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>
                            Dále by se měl vyplnit srdeční rytmus, ke je na výběr mezi sinusovým rytmem, četné SVES, četné KES a FISI/FLUSI. 
                            Následovně vyplnit klasifikaci hypertenze dle lékařského záznamu od pacienta, a to, jestli má optimální, normální, 
                            vysoký normální nebo hypertenzi stupně 1, 2 nebo 3. Nakonec je možné uvést medikaci, avšak pouze jestli pacient bere nebo nebere, 
                            výchozí hodnota je neuvedeno.
                        </p>
                        <p>
                            Pokud byl již pacient někdy v minulosti měřen, bude po zapsání rodného čísla načteno do všech kolonek v Údaje pacienta jeho parametry. 
                            Na zapisovateli je pak načtené hodnoty ověřit a aktualizovat. 
                        </p>
                        
                        <h3 className="mb-2 mt-5">
                            Měření
                        </h3>
                        <p>
                            Tlačítko Měření tlaku otevře vyskakovací okno. V tomto okně si je možné vybrat mód měření - 
                            a to dospělý, nebo novorozeně (prozatím se nepředpokládá, že by se měřila novorozeňata viz. typy manžet, ale aplikace to umí). 
                            Dále je vybrat metoda měření: 
                        </p>
                        <ul style={{textAlign: "left"}}>
                            <li>
                                <b>Metoda 1</b> - měření během vyfukování. Musí se nastavit maximální tlak, na který se manžeta na začátku nafoukne.
                            </li>
                            <li>
                                <b>Metoda 2</b> - měření během tlakování. Měřený tlak musí být v rozsahu 120-200 mmHg, jinak se použije metoda 1 a je také vhodné nastavit počáteční maximální tlak.
                            </li>
                            <li>
                                <b>Metoda 3</b> - měří během tlakování i vyfukování. Kdy během tlakování měří referenční hodnoty tlaku, vůči těm pak ověřuje měření při vyfukování. 
                                Nebere v potaz nastavený maximální počáteční tlak.
                            </li>
                        </ul>
                        <p>
                            Po měření se automaticky zapíšou naměřené hodnoty, což jsou tep (bpm), a všechna data z oscilometrické metody: systolický tlak (mmHg), 
                            střední tlak (mmHg) a diastolický tlak (mmHg). Parametry klasifikace hypertenze systolického/diastolického tlaku se vyplní automaticky 
                            dle rozsahu krevního tlaku, a to dle klasifikace WHO, tabulka níže.
                        </p>
                        <Table responsive striped bordered hover style={{textAlign: 'center'}}>
                            <thead style={{backgroundColor: "lightgray"}}>
                                <tr>
                                    <th>
                                        Kategorie
                                    </th>
                                    <th>
                                        Systolický tlak (mmHg)
                                    </th>
                                    <th>
                                        Diastolický tlak (mmHg)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Optimální
                                    </td>
                                    <td>
                                        {"< 120"}
                                    </td>
                                    <td>
                                        {"< 80"}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Normální
                                    </td>
                                    <td>
                                        120 - 129
                                    </td>
                                    <td>
                                        80 - 84
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Vysoký normální
                                    </td>
                                    <td>
                                        130 - 139
                                    </td>
                                    <td>
                                        85 - 89
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Hypertenze 1. stupně
                                    </td>
                                    <td>
                                        140 - 159
                                    </td>
                                    <td>
                                        90 - 99
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Hypertenze 2. stupně
                                    </td>
                                    <td>
                                        160 - 179
                                    </td>
                                    <td>
                                        100 - 109
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Hypertenze 3. stupně
                                    </td>
                                    <td>
                                        {"> 180"}
                                    </td>
                                    <td>
                                        {"> 110"}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>
                            Data z auskultační metody což jsou systolický tlak (mmHg) a diastolický tlak (mmHg) jsou zapsány zkušeným lékařem, 
                            kdy tyto hodnoty byly naměřeny spolu s hodnotami z oscilometrické metody. 
                        </p>
                        <p>
                            Po Uložení měření se všechny parametry uloží do databáze spolu s originálním ID a časovou známkou uložení záznamu. 
                            Dále se ukládá zvolená metoda měření a průběh tlakové křivky.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Functionality;