import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';

Font.register({
    family: 'Open Sans', fonts: [
        { src: 'OpenSans-Regular.ttf' },
        { src: 'OpenSans-Bold.ttf' }
    ]
  });

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 20,
      fontSize: 12,
      lineHeight: 1.5,
      fontFamily: 'Open Sans'
    },
    section: {
      marginBottom: 20
    },
    heading: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 10
    },
    subheading: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 6,
        marginTop: 6
    },
    row: {
      flexDirection: 'row',
      marginBottom: 5
    },
    key: {
      fontWeight: 'bold',
      marginRight: 10,
      width: '26%',
    textAlign: 'right'
    },
    key2: {
        fontWeight: 'bold',
        marginRight: 10,
        width: '18%',
      textAlign: 'right'
      },
    value: {
      flex: 1,
      fontWeight: 'normal',
    },
    image: {
        width: '25%',
        position: 'absolute'

    }
  });

function MeasurementPdf(props){

    return (
        <Document>
           <Page size="A4" style={styles.page}>
                {/*<View style={{alignItems: 'flex-end'}}>
                    <Image
                        style={styles.image}
                        src="bme.png"
                    />
                </View>*/}
                <View style={styles.section}>
                    <Text style={styles.heading}>Údaje o pacientovi</Text>
                    <View style={styles.row}>
                    <Text style={styles.key}>Rodné číslo: </Text>
                    <Text style={styles.value}>{props.personalId}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>Pohlaví: </Text>
                    <Text style={styles.value}>{props.gender}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>Datum narození: </Text>
                    <Text style={styles.value}>{props.birthday}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>Hmotnost: </Text>
                    <Text style={styles.value}>{props.weight + " kg"}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>Výška: </Text>
                    <Text style={styles.value}>{props.height + " cm"}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>BMI: </Text>
                    <Text style={styles.value}>{props.bmi}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>Medikace: </Text>
                    <Text style={styles.value}>{props.medication}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>Poruchy rytmu: </Text>
                    <Text style={styles.value}>{props.rhytmDisorders}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key}>Klasifikace hypertenze: </Text>
                    <Text style={styles.value}>{props.hypertensionClassification}</Text>
                    </View>
                    <View style={{borderTop: "1px solid black", marginTop: 10}} />
                    <Text style={styles.heading}>Data z měření</Text>
                    <View style={styles.row}>
                    <Text style={styles.key2}>Tep: </Text>
                    <Text style={styles.value}>{props.heartRate + " bpm"}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key2}>Metoda: </Text>
                    <Text style={styles.value}>{props.method}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.subheading}>Data z oscilometrické metody</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key2}>Systolický tlak: </Text>
                    <Text style={styles.value}>{props.sysPressureO + " mmHg"}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key2}>Střední tlak: </Text>
                    <Text style={styles.value}>{props.meanPressureO + " mmHg"}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key2}>Diastolický tlak: </Text>
                    <Text style={styles.value}>{props.diasPressureO + " mmHg"}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.subheading}>Data z auskultační metody</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key2}>Systolický tlak: </Text>
                    <Text style={styles.value}>{props.sysPressureA + " mmHg"}</Text>
                    </View>
                    <View style={styles.row}>
                    <Text style={styles.key2}>Diastolický tlak: </Text>
                    <Text style={styles.value}>{props.diasPressureA + " mmHg"}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default MeasurementPdf;